<template>
    <v-popover trigger="hover" placement="left">
        <slot></slot>

        <div slot="popover" style="padding: 15px;">
            <div class="info-popover">
                <img :src="data.image_zoom_out || defaultAvatar" class="info-popover__avatar">
                <div class="info-popover__wrap">
                    <a :href="`/auth/profile/${data.id}/`" class="info-popover__name" target="_blank">
                        {{data.full_name}}
                    </a>
                    <div class="info-popover__descr">
                        <div class="info-popover__position" v-if="data.positions"><span class="info-popover__title">Должность:</span> {{data.positions.name || null}}</div>
                        <div class="info-popover__struct" v-if="data.structure"><span class="info-popover__title">Подразделение:</span> {{data.structure[0].name || null }}</div>
                        <div class="info-popover__email"><span class="info-popover__title">Email:</span> <a :href="`mailto:${data.email}`" class="js-employer-email">{{data.email}}</a>
                        </div>
                        <div class="info-popover__phone" v-if="data.phone_number"><span class="info-popover__title">Телефон:</span> {{data.phone_number}}</div>
                    </div>
                </div>
            </div>
        </div>
    </v-popover>
</template>

<script>
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg';

    export default {
        name: "InfoPopover",
        data() {
            return {
                defaultAvatar
            }
        },
        props: {
            data: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style lang="scss" scoped>
    .info-popover {
        display: flex;
        align-items: center;
        &__avatar {
            max-width: 100px;
            max-height: 100px;
            border-radius: 50%;
        }
        &__wrap {
            margin-left: 20px;
            display: flex;
            flex-direction: column;
        }
        &__descr {
            font-size: 0.875rem;
        }
        &__title {
            font-weight: 500;
        }
    }
</style>
